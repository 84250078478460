@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

:root {
  --background: #121212;
  --font-color: #ffffff;
  --accent-primary: #5f71f6;
  --accent-secondary: #1b1b1b;
  --list-hover: #43454a;
  --button-hover: #535691;
  user-select: none;
  --background-frost-comp: rgba(27, 27, 27, 0.6);
}
/*
DESYNC COLORS
  --background: #121212;
--font-color: #ffffff;
--accent-primary: #5f71f6;
--accent-secondary: #1b1b1b;
--list-hover: #43454a;
--button-hover: #313b9f;
user-select: none;
}


KAFFEINE COLORS
:root {
  --background: #121212;
--font-color: #ffffff;
--accent-primary: #b59c02;
--accent-secondary: #1b1b1b;
--list-hover: #43454a;
--button-hover: #605716;
user-select: none;
}


 */


.appRoot {
  margin-left: 300px;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack items vertically */
  }


  .BuyKeys {
    flex-direction: column;
  }
}
.App {
  display: flex;
  flex-direction: row; /* Arrange sidebar and content side by side */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  width: 100vw;
  height: 100vh;

}

hr {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 1px;
  background: rgba(255, 255, 255, 0.2);
  min-width: 80%;
  margin-top: 4ch;
  margin-bottom: 4ch;
}

.BoxItem {
  margin-left: 1ch;
  margin-right: 1ch;
  border: 20px solid var(--accent-secondary);
  background: #1B1B1B;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-overflow: clip;
  animation: popupAnim 0.5s ease-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Adds some depth */
}


.BoxScrollContainer {
  overflow-y: auto;
  max-height: 700px;

}

.KeyScrollContainer {
  overflow-y: auto;
  max-height: 84vh;
  max-width: 80vw;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--background);
  font-family: Poppins, sans-serif;
  font-weight: 700;
}


.hamburger {
  display: none;
  font-size: 30px;
  background: none;
  border: none;
  color: white;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 3000;
}

.close-btn-sidebar {
  display: none;
  font-size: 30px;
  background: none;
  border: none;
  color: white;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .App {
    flex-direction: column;
    align-items: center;
  }

  .Sidebar {
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    background-color: var(--accent-secondary);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    height: 100vh;

  }

  .Sidebar.open {
    transform: translateX(0);
  }

  .hamburger {
    display: block;
  }

  .close-btn {
    display: block;
    padding-top: 2ch;
  }

  .SidebarList {
    padding: 0;
    position: static;        /* Remove the fixed positioning */
    display: flex;          /* Reset display to block */
    flex-direction: column; /* Reset flex-direction */
    justify-content: center;/* Remove justify-content */
    min-width: 100%;
  }
  .SidebarList .row {
    margin: 2ch;
  }
}



.Sidebar {
  z-index: 1001;
  box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.1);
  flex: 0 0 20%;
  color: white;
}

.Sidebar .background {
  background-color: var(--accent-secondary);
  position: fixed;
  height: 200vh;
  width: 20vw;
}

.Sidebar .Logout {
  position: absolute;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.Sidebar .linkDeco {
  text-decoration: none;
}


.Sidebar h2 {
  padding-bottom: 10px;
  text-align: center;
}

.SidebarList {
  padding: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 20%;
  justify-content: center;
}

.SidebarList .row {
  text-decoration: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Adds some depth */

  height: 40px;
  color: white;
  list-style-type: none;
  margin: 1ch 2ch 1ch 2ch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--background);
  padding: 10px;
  /*border: solid 10px var(--background);*/
  border-radius: 10px;
  cursor: pointer;
}

.SidebarList .row:hover {
  animation: myanim 0.4s;
  transition: 0.3s ease-out;

  cursor: pointer;
  background-color: var(--list-hover);
}
@keyframes myanim {
  50% {
    text-indent: 10px;
  }
}

.SidebarList #active {
  background-color: var(--accent-primary);


}

.SidebarList .row #icon {
  flex: 25%;
  display: grid;
  place-items: center;
}

.SidebarList .row #title {
  flex: 75%;
}

.SidebarList .row a {
  text-decoration: none;
  color:inherit;
}

.Sidebar button {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Adds some depth */

  border: transparent;
  display: inline-block;
  font-size: 15px;
  margin-top: 3ch;
  cursor: pointer;
  padding: 10px 20px;
  background-color: var(--accent-primary);
  color: var(--font-color);
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s;
  border-radius: 5px;
}

.Sidebar button:hover {
  background-color: var(--button-hover);

}


.PageDefaults {
  color: white;
}


.PageDefaults button:not(.copyButton) {
  border: transparent;
  display: inline-block;
  font-size: 15px;
  margin-top: 3ch;
  cursor: pointer;
  padding: 10px 20px;
  background-color: var(--accent-primary);
  color: var(--font-color);
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s;
  border-radius: 5px;
}

/* Specific styles for the copy button if needed */
.copyButton {
  padding-left: 2ch;
  background-color: transparent ;
  z-index: 100;

}

.PageDefaults button:hover {
  background-color: var(--button-hover);
}

.BoxItemFlexBox {
  width: 80vw;
  margin-block-start: 2ch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

}


.BuyKeys {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;


}

.BuyKeys .InfoSection {
  color: white;
}

.BuyKeys .InfoSection h2 {
  text-align: center;
}

.BuyKeys .SelectStyle {
  background: var(--background);
}


.DarkBG{
  background-color: var(--background);
}


.KeyManage {
  color: white;
}

.KeyManage h2 {
  text-align: center;
}

/*"asgA": {
"started":false,
 "length": "1 Day",
 "product": "Valorant",
 "freezed": false,
 "vouched": false,
  "created": "2024-08-01"}} */

.KeyManage .InputKey {
  display: flex;
  flex-direction: column;
  color: white;
}

.KeyManage .KeyHistory {
  display: flex;
  flex-direction: column;
  color: white;
}

.ProductManage {
  color: white;
}

.ProductManage h2 {
  text-align: center;
}

.ProductManage .ProductManageFlexbox {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.ProductManage .ProductManageFlexbox .PricingSettings {
  display: flex;
  flex-direction: column;
  color: white;
  flex: 0 50%;
}

.ProductManage .ProductManageFlexbox .OtherSettings {
  flex: 1 50%;
}


.UserInfo {
  color: white;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}

.UserInfo h2 {
  text-align: center;
}
















.ActionHistory {
  color: white;
}

.ActionHistory h2 {
  text-align: center;
}

.ActionHistory .SortedActions{
  display: flex;
  flex-direction: column;
}

.HistoryResults {
}

.HistoryResults .HistoryList {
  overflow-y: auto;
  max-height: 500px;
  height: auto;
  padding-left: 2ch;
  padding-right: 2ch;
  padding-bottom: 2ch;
}
.HistoryResults .HistoryList .row {



  text-decoration: none;
  color: white;
  list-style-type: none;
  margin: 2ch 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  background: var(--background);
  /*border: solid 10px var(--background);*/
  padding: 10px;
  border-radius: 10px;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease; /* Add transition for smooth changes */
}


.HistoryResults .HistoryList .row .rowText {
  margin: 2ch 3ch;
  overflow: hidden;
  text-align: left;
  max-width: 20ch;
}

.HistoryResults .HistoryList .row.shrink {
  transform: scale(0.5);
  opacity: 0;
}

.HistoryResults .HistoryList .row:hover {
  transition: background-color 0.3s ease-out;
  cursor: pointer;
  background-color: var(--list-hover);
}

.HistoryResults .HistoryList .active {
  text-decoration: none;
  color: white;
  list-style-type: none;
  margin: 2ch 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /*border: solid 10px var(--background);
  border-radius: 20px;
  background-color: var(--accent-primary);*/
  border: 0px;
  padding: 10px;
  background: var(--accent-primary);
  backdrop-filter: blur(10px); /* Adjust blur to your liking */
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Adds some depth */


}

.HistoryResults .HistoryList .row.active:hover {
  background-color: var(--accent-primary); /* Ensures active row doesn't change color on hover */

}

.HistoryResults .HistoryList .active em {
  color: white;
}

.HistoryResults .HistoryList #icon {
  flex: 25%;
  display: grid;
  place-items: center;
}

.HistoryResults .HistoryList #title {
  flex: 100%;
}

.HistoryResults .HistoryList .row a {
  text-decoration: none;
  color: inherit;
}


.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logout {
  width: 80vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}



.login .loginitem {
  margin: 2ch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2ch;
}


@keyframes popupAnim {
  0% {
    top: -100px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 20px;
  }
}

.notifyBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  width: 100vw;
}


.error-box, .success-box {
  margin: 1ch;
  transform:translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  text-align: center;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out, height 0.3s ease-out;
  animation: popupEnter 0.3s ease-out forwards;

}

.error-box {
  background-color: red;
  color: white;

}

.success-box {
  background-color: var(--accent-primary);
  color: white;
}

.error-box.enter, .success-box.enter {
  animation: popupEnter 0.3s ease-out forwards;
}

.error-box.exit, .success-box.exit {
  animation: popupExit 0.3s ease-out forwards;
}

@keyframes popupEnter {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popupExit {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}


.orderDetailsFlexbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}

.orderDetailsFlexbox .amounts {
  flex: 0 50%;
  padding-right: 2ch;
}

.orderDetailsFlexbox .cost {
  padding-left: 4ch;
  position: relative;
  overflow: hidden;
  white-space: nowrap;

}
.orderDetailsFlexbox .cost p {

  transform: translateX(-100%);
  animation: slideIn 0.3s forwards;

}




@keyframes paymentAnim {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}


.text-select {
  user-select: text;
}
.popup {
  backdrop-filter: blur(5px);
  animation: paymentAnim 0.1s ease-out;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  justify-content: center;
  align-items: center;
  z-index: 1111;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  user-select: text;
}

@keyframes sweepInVisibility {
  0% {
    opacity: 0;
    scale: 0.9;
  }
  70% {
    opacity: 1;
  }
  100% {


    scale: 1;
  }
}

@keyframes sweepOutVisibility {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.popupFadeOut {
  opacity: 0;
  animation: sweepOutVisibility 0.25s;
}

.popupBackgroundFadeOut{
  opacity: 0;
  animation: fadeOut 0.25s;
}


.popup-content {
  border: 20px solid var(--accent-secondary);
  background: var(--accent-secondary);
  border-radius: 10px;
  width: 350px;
  text-align: center;
  position: relative;
  transition: all 0.1s;
  overflow-y: auto;
  animation: sweepInVisibility 0.2s ease-out forwards;
}

.popup-content-large {
  border: 20px solid #1B1B1B;
  background: #1B1B1B;
  border-radius: 10px;
  width: auto;
  position: relative;
  transition: all 0.1s;
  max-width: 80vw;
  animation: sweepInVisibility 0.2s ease-out forwards;

}

.popup-content .buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
}

.vSort {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 2ch;
  padding-right: 2ch;
}

.hSort {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
}




.popup-content .buttons button {
  margin: 1ch;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Adds some depth */
}

.close-btn {
  position: absolute;
  top: -10px;
  right: 5px;
  font-size: 20px;
  cursor: pointer;
}


.pendingOrderFlexbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1ch;
}


.pendingOrderFlexbox .pendingOrderInfo {
  text-align: left;
  max-width: fit-content;
}
.pendingOrderFlexbox .pendingOrderInfo .textDeco {
  display: flex;
  width: fit-content;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 1ch 2ch 1ch 2ch;
  background: var(--background);
  border: 1px solid var(--background);
  border-radius: 10px;
  margin-bottom: 1ch;
  align-items: center;
}

.pendingOrderFlexbox .pendingOrderPayment {
  flex: 1 50%;
  padding-left: 2ch;
  padding-right:2ch;
}

em {
  color: var(--accent-primary);
  font-style: normal;
}



@keyframes slideIn {
  to {
    transform: translateX(0);
  }
}

.emoji-container {
  height: 50px;
  display: flex;
  margin: 10px 0px 10px 0;
  justify-content: center;
}

.emoji {
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  transition: ease-out 0.2s, border 0.3s;
  align-items: center;
  opacity: 0.7;
}


.selected {
  transform: translateY(-5px);
  scale: 1.2;
  opacity: 1;
}




.keyMenu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  gap: 2ch;
  align-items: flex-start;
}

.keyMenu .keySearch {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 2ch 0 2ch;
  animation: popupAnim 0.5s ease-out;
}

.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}


.buttons button{
  margin-right: 2ch;
}

.keyBox {
  margin: 2ch;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: left; /* Space out the title and timestamp */
  align-items: flex-start;
  transition: all 0.3s;
  background: var(--accent-secondary);
  padding: 20px;
  border: 1px solid var(--accent-secondary);
  cursor: pointer;
  height: 100%; /* Ensure the keyBox takes up full height */
}


.keyBox .topFlex {
  display: flex; /* Ensure it's a flex container */
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between; /* Pushes the title left and date right */
  align-items: center; /* Aligns items vertically in the center */
  width: 100%;
  margin-bottom: 2ch;
}

.keyBox .topFlex .orderID {
  margin-top:-1ch;
  width: fit-content; /* Ensures the title is just wide enough */
  align-items: flex-start;
}

.keyBox .topFlex .orderDate {
  margin: 0 0 0 0;
  align-self: flex-start;
  margin-left: 2ch;
  margin-right: 1ch;
}


.keyBox .orderDetails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0 2ch; /* Add space between flex items */
}





.active {
    border: 1px solid var(--accent-primary);
}





.keyBox .keyDeco {
  cursor: auto;
  padding: 1ch 2ch 1ch 2ch;
  /*border: 1px solid var(--background);*/
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
  background-color: var(--background);
  backdrop-filter: blur(10px); /* Adjust blur to your liking */
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Adds some depth */



}

.avatarWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.avatar {
  max-width: 25px;
  border-radius: 12px;
  margin-right: 5px;
}

.keyBox .keyDeco img {
  max-width: 25px;
  border-radius: 12px;
}


.keyActions {
  animation: popupAnim 0.5s ease-out;
}

@keyframes popupAnim {
  0% {
    top: -100px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 20px;
  }
}

.toolTip {
 position: relative;
  display: inline-block;
  cursor: pointer;

}

.toolTip .inside {
  animation: paymentAnim 0.2s ease-out;
  width: fit-content;
  position: absolute;
  background-color: var(--background);
  color: white;
  padding: 2ch;
  border: 1px solid var(--accent-primary);
  border-radius: 10px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px;
  z-index: 2000;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}


.toolTipContainer {
  display: flex;
  flex-direction: row;
  align-items: center; /* Center items vertically */
  gap: 8px; /* Add some space between the text and the icon */
}

.toolTipContainer p {
  margin: 0;
  flex: 1; /* Take up remaining space */
}

.toolTipContainer #tt {
  display: flex;
  align-items: center;
  cursor: pointer;
}


.featureList {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  overflow-wrap: break-word;
  max-height: 300px;
  overflow-y: scroll;
  max-width: 100%;
}


.addIconSort {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

@keyframes hoverAnim {

}

.addIconSort .icon {
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 50%;
}

.addIconSort .icon:hover {
  background: rgba(181,156,2,0.48)
}


.mediaDeco {
  max-width: 400px;
  margin-top: 2ch;
  margin-bottom: 2ch;
  border: 2px solid var(--accent-primary);
  border-radius: 10px;
}


.categoryImage {
  border: 2px solid var(--accent-primary);
  border-radius: 10px;
}